<!-- Error404.vue -->

<template>
  <div class="error-container">
    <div class="header-gradient"></div>
    <h1>{{$t('error404.title')}}</h1>
    <p>{{$t('error404.subtitle')}}</p>
    <b-button to="/" class="d-bloc mx-auto" variant="black">{{$t('error404.buttonLabel')}}</b-button>
  </div>
</template>

<style scoped>
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Ocupa todo el alto de la pantalla */
  text-align: center;
}


.header-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Altura del degradado, ajusta según tus preferencias */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); /* Degradado desde negro hasta transparente */
}

h1 {
  font-size: 2em;
  color: #ff6347; /* Color rojo como ejemplo, puedes cambiarlo */
}

p {
  font-size: 1.2em;
  margin-top: 10px;
}


/* Puedes agregar más estilos según tus necesidades */
</style>

<script>
export default {
  name: 'Error404',
};
</script>
